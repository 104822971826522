
import { VIEW_ALERT_RULES, STORE_ALERT_RULE, UPDATE_ALERT_RULE, DELETE_ALERT_RULE } from "../../schema/alertRules";


export default {
    namespaced: true,
    state : {
        alert_rules: [],
    },
    getters: {
        alert_rules: (state) => {
            return state.alert_rules
        },
        alert_rule_by_id: (state) => (id) => {
            return state.alert_rules.find(item => item.id === id)
        }
    },
    mutations: {
        set_listing: (state, items) => {
            state.alert_rules = items;
        },
        update: (state, item) => {
            const index = state.alert_rules.findIndex(a => a.id === item.id);
            if (index !== -1) {
                state.alert_rules.splice(index, 1, item);
            } else {
                state.alert_rules.push(item);
            }
        },
        delete: (state, id) => {
            const index = state.alert_rules.findIndex(a => a.id === id);
            if (index !== -1) {
                state.alert_rules.splice(index, 1);
            }
        },
    },
    actions: {
        storeOrUpdate: ({ commit, rootGetters }, payload) => {
            let typeName = payload.create ? 'storeAlertRule' : 'updateAlertRule';

            return new Promise((resolve, reject) => {
                rootGetters.apollo.mutate({
                    mutation: payload.create ? STORE_ALERT_RULE : UPDATE_ALERT_RULE,
                    variables: {
                        id: payload.id,
                        projectId: payload.projectId,
                        input: payload.input
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('update', data[typeName]);
                        resolve(data[typeName]);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },
        load_alert_rules: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.query({
                    query: VIEW_ALERT_RULES,
                    variables: {
                        projectId: payload.project_id,
                        filters: payload.filters,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('set_listing', data.alertRules.data);
                        resolve(data.alertRules);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });

            });
        },
        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.mutate({
                    mutation: DELETE_ALERT_RULE,
                    variables: {
                        id: payload.id,
                        projectId: payload.project_id
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('delete', payload.id);
                        resolve(data.deleteAlertRule);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },
    },
}