import gql from 'graphql-tag';

const alertRuleFragment = gql`
    fragment alertRuleFragment on AlertRule {
        id
        name
        description
        project_id
        registry_id
        is_active
        alert_messages {
            message 
            language
        }
        conditions {
            id
            name
            operator
            state
            value
            hysteresis
        }
    }
`;

export const VIEW_ALERT_RULES = gql`
    query getAlertRules($projectId: Int64!, $filters: FiltersInput!) {
        alertRules(projectId: $projectId, filters: $filters) {
            data {
                ...alertRuleFragment
            }
            pagination {
                total
                last_page
            } 
        }
    }
    ${alertRuleFragment}
`;

export const VIEW_ALERT_RULE = gql`
    query getAlertRule($projectId: Int64!, $id: Int64!) {
        alertRule(projectId: $projectId, id: $id) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const STORE_ALERT_RULE = gql`
    mutation storeAlertRule($projectId: Int64!, $input: AlertRuleInput!) {
        storeAlertRule(projectId: $projectId, input: $input) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const UPDATE_ALERT_RULE = gql`
    mutation updateAlertRule($projectId: Int64!, $id: Int64!, $input: AlertRuleUpdateInput!) {
        updateAlertRule(projectId: $projectId, id: $id, input: $input) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const DELETE_ALERT_RULE = gql`
    mutation deleteAlertRule($projectId: Int64!, $id: Int64!) {
        deleteAlertRule(projectId: $projectId, id: $id) {
            ...alertRuleFragment
        }                 
    }
    ${alertRuleFragment}
`;