import Vue from 'vue';
import Vuex from 'vuex';
import businessUnit from '@/store/modules/businessUnit';
import registries from '@/store/modules/registries';
import deviceTypes from '@/store/modules/deviceTypes';
import utilities from '@/store/modules/utilities';
import connectors from '@/store/modules/connectors';
import connectorsLinks from '@/store/modules/connectors/links';
import devices from '@/store/modules/devices';
import topics from '@/store/modules/topics';
import deviceActions from '@/store/modules/actions/deviceActions';
import actions from '@/store/modules/actions/actions';
import actionTypes from '@/store/modules/actions/actionTypes';
import deviceCommands from '@/store/modules/deviceCommands';
import deviceCommandsCreate from '@/store/modules/deviceCommands/create';
import deviceCommandsShow from '@/store/modules/deviceCommands/show';
import ui from '@/store/modules/ui';
import VuexPersistence from 'vuex-persist';
import alertRules from './modules/alertRules';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    modules: ['ui', 'businessUnit', 'registries', 'deviceTypes'],
});

export default new Vuex.Store({
    state: {
        apollo: null,
    },
    mutations: {
        apollo: (state, apollo) => {
            state.apollo = apollo;
        },
    },
    getters: {
        apollo: (state) => {
            return state.apollo;
        },
    },
    actions: {},
    modules: {
        actions,
        alertRules,
        actionTypes,
        deviceActions,
        businessUnit,
        connectors,
        connectorsLinks,
        registries,
        deviceTypes,
        utilities,
        devices,
        topics,
        deviceCommands,
        deviceCommandsCreate,
        deviceCommandsShow,
        ui,
    },
    plugins: [vuexLocal.plugin],
});
