import gql from 'graphql-tag';

const pubSubTopicFragment = gql`
    fragment pubSubTopicFragment on PubSubTopic {
        id
        name
        is_default
        default_type
        updated_at
        deleted_at
        status
        inbound
        registry_topic_count
    }
`;

export const SUBSCRIPTION_PUB_SUB_TOPICS = gql`
    subscription livePubSubTopics($projectId: Int64!) {
        livePubSubTopics(projectId: $projectId) {
            ...pubSubTopicFragment
        }
    }
    ${pubSubTopicFragment}
`;

export const CREATE_PUB_SUB_TOPIC = gql`
    mutation storePubSubTopic($projectId: Int64!, $input: PubSubTopicInput!) {
        storePubSubTopic(projectId: $projectId, input: $input) {
            ...pubSubTopicFragment
        }
    }
    ${pubSubTopicFragment}
`;

export const UPDATE_PUB_SUB_TOPIC = gql`
    mutation updatePubSubTopic($projectId: Int64!, $id: Int64!, $input: PubSubTopicInput!) {
        updatePubSubTopic(projectId: $projectId, id: $id, input: $input) {
            ...pubSubTopicFragment
        }
    }
    ${pubSubTopicFragment}
`;

export const DELETE_PUB_SUB_TOPIC = gql`
    mutation deletePubSubTopic($projectId: Int64!, $id: Int64!) {
        deletePubSubTopic(projectId: $projectId, id: $id) {
           ...pubSubTopicFragment
        }
    }
    ${pubSubTopicFragment}
`;
