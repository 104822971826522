import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import NoAccess from '../views/errors/NoAccess.vue';
import NotFound from '../views/errors/NotFound.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    {
        name: '404',
        path: '/404',
        component: NotFound,
        meta: { breadcrumb: [] },
    },
    {
        path: '*',
        redirect: '404',
    },
    {
        path: '/access-forbidden',
        name: 'access-forbidden',
        component: NoAccess,
        meta: { breadcrumb: [] },
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            breadcrumb: [],
            need_project: true,
            need_registry: true,
        },
    },

    // Registry
    {
        path: '/registries',
        name: 'registries',
        component: () =>
            import(
                /* webpackChunkName: "projects" */ '../views/registries/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Registries', link: '/registries' }],
            need_project: true,
        },
    },
    {
        path: '/registries-create',
        name: 'registries-create',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "projects" */ '../views/registries/Create.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'New Registry', link: '/registries-create' }],
            need_project: true,
        },
    },
    {
        path: '/registries/:id/edit',
        name: 'registries-edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "projects" */ '../views/registries/Edit.vue'
            ),
        meta: {
            need_project: true,
        },
    },
    {
        path: '/registries/:id',
        name: 'registries-show',
        redirect: '/registries/:id/configuration',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "projects" */ '../views/registries/Show.vue'
            ),
        meta: {
            need_project: true,
        },
        children: [
            {
                path: 'configuration',
                name: 'registries-configuration',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/registries/views/Configuration.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Registries', link: '/registries' },
                        { name: ':id', link: '' },
                        { name: 'Configuration', link: '' },
                    ],
                },
            },
            {
                path: 'pubsub',
                name: 'registries-pubsub',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/registries/views/PubSub.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Registries', link: '/registries' },
                        { name: ':id', link: '' },
                        { name: 'PubSub Topics', link: '' },
                    ],
                },
            },
            {
                path: 'advance',
                name: 'registries-advance',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/registries/views/Advance.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Registries', link: '/registries' },
                        { name: ':id', link: '' },
                        { name: 'Advance', link: '' },
                    ],
                },
            },
        ],
    },

    // Devices
    {
        path: '/devices',
        name: 'devices',
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Devices', link: '/devices' }],
            need_project: true,
            need_registry: true,
        },
    },
    {
        path: '/gateways',
        name: 'gateways',
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices/Gateways.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Gateways', link: '/gateways' }],
            need_project: true,
            need_registry: true,
        },
    },
    {
        path: '/device-create',
        name: 'devices-create',
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices/Create.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'New Device', link: '/device-create' }],
            need_project: true,
            need_registry: true,
        },
    },
    {
        path: '/devices/:id',
        name: 'device',
        redirect: '/devices/:id/edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices/Device.vue'
            ),
        meta: {
            need_project: true,
            need_registry: true,
        },
        children: [
            {
                path: 'edit',
                name: 'devices-edit',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/Edit.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '' },
                        { name: 'Configuration', link: '' },
                    ],
                },
            },
            {
                path: 'commands',
                name: 'devices-commands',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/Commands.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '/devices/:id/edit' },
                        { name: 'Commands', link: '' },
                    ],
                },
            },
            {
                path: 'actions',
                name: 'devices-actions',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/Actions.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '/devices/:id/edit' },
                        { name: 'actions', link: '' },
                    ],
                },
            },
            {
                path: 'states',
                name: 'devices-states',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/States.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '/devices/:id/edit' },
                        { name: 'States', link: '' },
                    ],
                },
            },
            {
                path: 'security',
                name: 'devices-auth',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/DeviceAuthentication.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '/devices/:id/edit' },
                        { name: 'Security', link: '' },
                    ],
                },
            },
            {
                path: 'advance',
                name: 'devices-advance',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/Advance.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '/devices/:id/edit' },
                        { name: 'Advance', link: '' },
                    ],
                },
            },
            {
                path: 'terminal',
                name: 'devices-terminal',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices/sub_views/Terminal.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices', link: '/devices' },
                        { name: ':id', link: '/devices/:id/edit' },
                        { name: 'Terminal', link: '' },
                    ],
                },
            },
        ],
    },
    {
        path: '/devices-commands',
        name: 'devices-commands-index',
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices-commands/Index.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Devices Commands', link: '/devices-commands' },
            ],
            need_project: true,
            need_registry: true,
        },
    },
    {
        path: '/devices-commands/create',
        name: 'devices-commands-create',
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices-commands/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Devices Commands', link: '/devices-commands' },
                { name: 'Create', link: '' },
            ],
            need_project: true,
            need_registry: true,
        },
    },
    {
        path: '/devices-commands/:batch_id',
        name: 'devices-commands-show',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/devices-commands/Show.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Devices Commands', link: '/devices-commands' },
                { name: ':batch_id', link: '' },
            ],
            need_project: true,
            need_registry: true,
        },
        children: [
            {
                path: 'summary/:status',
                name: 'summary-by-status',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "devices" */ '../views/devices-commands/views/BatchCommandsSummaryByStatus.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Devices Commands', link: '/devices-commands' },
                        { name: ':batch_id', link: '' },
                    ],
                },
            },
        ],
    },

    // Device Types
    {
        path: '/device-types',
        name: 'device-types-index',
        component: () =>
            import(
                /* webpackChunkName: "device-types" */ '../views/device_types/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Device Types', link: '' }],
        },
    },
    {
        path: '/device-types-create',
        name: 'device-types-create',
        component: () =>
            import(
                /* webpackChunkName: "device-types" */ '../views/device_types/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Device Types', link: '/device-types' },
                { name: 'New Device Type', link: '' },
            ],
        },
    },
    {
        path: '/device-types/:device_type_id',
        name: 'device-types-edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "device-types" */ '../views/device_types/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Device Types', link: '/device-types' },
                { name: ':device_type_id', link: '' },
            ],
        },
    },

    // Actions
    {
        path: '/actions',
        redirect: '/actions/list',
        name: 'actions-index',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "actions" */ '../views/actions/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Actions', link: '/actions' }],
            need_project: true,
        },
        children: [
            {
                path: 'list',
                name: 'actions-list',
                component: () =>
                    import(
                        /* webpackChunkName: "actions" */ '../views/actions/List.vue'
                    ),
                meta: {
                    breadcrumb: [{ name: 'Actions', link: '' }],
                },
            },
            {
                path: 'show/:action_id',
                props: true,
                name: 'actions-show',
                component: () =>
                    import(
                        /* webpackChunkName: "actions" */ '../views/actions/Show.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Actions', link: '' },
                        { name: ':action_id', link: '' },
                    ],
                },
            },
            {
                path: 'action-types',
                name: 'action-types-index',
                component: () =>
                    import(
                        /* webpackChunkName: "actions" */ '../views/actions/action_types/Index.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Actions', link: '' },
                        { name: 'Action Types', link: '' },
                    ],
                },
            },
            {
                path: 'action-types-create',
                name: 'action-types-create',
                component: () =>
                    import(
                        /* webpackChunkName: "actions" */ '../views/actions/action_types/Create.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Action Types', link: '/action-types' },
                        { name: 'New Action Type', link: '' },
                    ],
                },
            },
            {
                path: 'action-types/:action_type_id',
                name: 'action-types-edit',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "actions" */ '../views/actions/action_types/Edit.vue'
                    ),
                meta: {
                    breadcrumb: [
                        { name: 'Action Types', link: '/action-types' },
                        { name: ':action_type_id', link: '' },
                    ],
                },
            },
        ],
    },

    // PubSub Topics
    {
        path: '/pub-sub-topics',
        name: 'pub-sub-topics-index',
        component: () =>
            import(
                /* webpackChunkName: "pub_sub_topics" */ '../views/pub_sub_topics/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'PubSub Topics', link: '' }],
        },
    },
    {
        path: '/pub-sub-topics-create',
        name: 'pub-sub-topics-create',
        component: () =>
            import(
                /* webpackChunkName: "pub_sub_topics" */ '../views/pub_sub_topics/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'PubSub Topics', link: '/pub-sub-topics' },
                { name: 'New Topic', link: '' },
            ],
        },
    },
    {
        path: '/pub-sub-topics/:id',
        name: 'pub-sub-topics-edit',
        props: (route) => ({ id: parseInt(route.params.id) }),
        component: () =>
            import(
                /* webpackChunkName: "pub_sub_topics" */ '../views/pub_sub_topics/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'PubSub Topics', link: '/pub-sub-topics' },
                { name: ':id', link: '' },
            ],
        },
    },

    // Versions
    {
        path: '/device-types/:device_type_id/versions-create',
        name: 'versions-create',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "device-types" */ '../views/device_types/versions/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Device Types', link: '/device-types' },
                {
                    name: ':device_type_id',
                    link: '/device-types/:device_type_id',
                },
                { name: 'Versions', link: '/device-types/:device_type_id' },
                { name: 'New Version', link: '' },
            ],
        },
    },
    {
        path: '/device-types/:device_type_id/versions/:id',
        name: 'versions-edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "device-types" */ '../views/device_types/versions/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Device Types', link: '/device-types' },
                {
                    name: ':device_type_id',
                    link: '/device-types/:device_type_id',
                },
                { name: 'Versions', link: '/device-types/:device_type_id' },
                { name: ':id', link: '' },
            ],
        },
    },

    // Protocols
    {
        path: '/protocols',
        name: 'protocols-index',
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/protocols/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Protocols', link: '' }],
            need_admin: true,
        },
    },
    {
        path: '/protocols-create',
        name: 'protocols-create',
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/protocols/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Protocols', link: '/protocols' },
                { name: 'New Protocol', link: '' },
            ],
            need_admin: true,
        },
    },
    {
        path: '/protocols/:id',
        name: 'protocols-edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/protocols/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Protocols', link: '/protocols' },
                { name: ':id', link: '' },
            ],
            need_admin: true,
        },
    },

    // Cloud Providers
    {
        path: '/providers',
        name: 'providers-index',
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/cloud_providers/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Providers', link: '' }],
            need_admin: true,
        },
    },
    {
        path: '/providers-create',
        name: 'providers-create',
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/cloud_providers/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Providers', link: '/providers' },
                { name: 'New Provider', link: '' },
            ],
            need_admin: true,
        },
    },
    {
        path: '/providers/:id',
        name: 'providers-edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/cloud_providers/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Providers', link: '/providers' },
                { name: ':id', link: '' },
            ],
            need_admin: true,
        },
    },

    // Communication methods
    {
        path: '/communication-methods',
        name: 'communication-methods-index',
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/communication_methods/Index.vue'
            ),
        meta: {
            breadcrumb: [{ name: 'Communication Methods', link: '' }],
            need_admin: true,
        },
    },
    {
        path: '/communication-methods-create',
        name: 'communication-methods-create',
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/communication_methods/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Methods', link: '/communication-methods' },
                { name: 'New Communication methods', link: '' },
            ],
            need_admin: true,
        },
    },
    {
        path: '/communication-methods/:id',
        name: 'communication-methods-edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "utilities" */ '../views/communication_methods/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                {
                    name: 'Communication methods',
                    link: '/communication-methods',
                },
                { name: ':id', link: '' },
            ],
            need_admin: true,
        },
    }, 
    
    // Alert Rules
    {
        path: '/alert-rules',
        name: 'alert-rules-index',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "alerts" */ '../views/alerts_rules/Index.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Alert Rules', link: '/alert-rules' },
            ],
        },
    }, {
        path: '/alert-rules-create',
        name: 'alert-rules-create',
        component: () =>
            import(
                /* webpackChunkName: "alerts" */ '../views/alerts_rules/Create.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Alert Rules', link: '/alert-rules' },
                { name: ':id', link: '' },
            ],
        },
    },{
        path: '/alert-rules-edit/:id',
        name: 'alert-rules-edit',
        props: (route) => ({ id: parseInt(route.params.id) }),
        component: () =>
            import(
                /* webpackChunkName: "alerts" */ '../views/alerts_rules/Edit.vue'
            ),
        meta: {
            breadcrumb: [
                { name: 'Alert Rules', link: '/alert-rules' },
                { name: ':id', link: '' },
            ],
        },
    },
];

routes.push(...getRouteConnector());
routes.push(...getRouteConnectorLink());

if (process.env.NODE_ENV == 'local') {
    routes.push(...getRouteLocal());
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    routes,
});

if (window !== undefined) {
    router.scrollBehavior = () => {
        return { x: 0, y: 0 };
    };
}

router.beforeEach(async (to, from, next) => {
    let current_user = store.getters['oauth/currentUser'];

    if (
        !current_user &&
        !['oauth-login', 'oauth-logout', 'oauth-callback'].includes(to.name)
    ) {
        if (store.getters['oauth/isOAuthTokenValid']()) {
            await store.dispatch('oauth/get_current_user');
            current_user = store.getters['oauth/currentUser'];
        }

        if (!current_user) {
            next({ name: 'oauth-login' });
            return;
        }
    }

    if (store.getters.apollo) {
        if (to.name === 'oauth-project-selector-callback') {
            let project = store.getters['businessUnit/project'];
            if (
                project === undefined ||
                project !== parseInt(to.query.project)
            ) {
                await store.dispatch('businessUnit/switch_project', {
                    project_id: parseInt(to.query.project),
                });
            }

            next({ name: 'home' });
            return;
        }
    }

    if (to.meta.need_admin) {
        let checkAdmin = false;
        current_user.roles.forEach((value) => {
            if (value.name === 'container_admin') {
                checkAdmin = true;
            }
        });

        if (!checkAdmin) {
            next({ name: 'access-forbidden' });
            return;
        }
    }

    if (to.meta.validActivated != null) {
        if (!store.getters[to.meta.validActivated]) {
            next({ name: 'access-forbidden' });
            return;
        }
    }

    if (to.meta.need_project || to.meta.need_registry) {
        await store.dispatch('businessUnit/wait_project_load', {});
    }

    if (to.meta.need_project && !store.getters['businessUnit/project']) {
        next({ name: 'oauth-project-selector' });
    } else if (to.meta.need_registry && !store.getters['registries/registry']) {
        next({ name: 'registries' });
    } else next();
});

function getRouteConnector() {
    return [
        {
            path: '/connectors',
            name: 'connectors-index',
            component: () =>
                import(
                    /* webpackChunkName: "connectors" */ '../views/connectors/Index.vue'
                ),
            meta: {
                breadcrumb: [{ name: 'Connectors', link: '' }],
                validActivated: 'connectors/activated',
            },
        },
        {
            path: '/connectors-create',
            name: 'connectors-create',
            component: () =>
                import(
                    /* webpackChunkName: "connectors" */ '../views/connectors/Create.vue'
                ),
            meta: {
                breadcrumb: [
                    { name: 'Connectors', link: '/connectors' },
                    { name: 'New Connector', link: '' },
                ],
                validActivated: 'connectors/activated',
            },
        },
        {
            path: '/connectors/:id',
            name: 'connectors-edit',
            props: (route) => ({ id: parseInt(route.params.id) }),
            component: () =>
                import(
                    /* webpackChunkName: "connectors" */ '../views/connectors/Edit.vue'
                ),
            meta: {
                breadcrumb: [
                    { name: 'Connectors', link: '/connectors' },
                    { name: ':id', link: '' },
                ],
                validActivated: 'connectors/activated',
            },
        },
    ];
}

function getRouteConnectorLink() {
    return [
        {
            path: '/connectors/:connectorId/links',
            name: 'connectors-links-index',
            props: (route) => ({
                connectorId: parseInt(route.params.connectorId),
            }),
            component: () =>
                import(
                    /* webpackChunkName: "connectors-links" */ '../views/connectors/links/Index.vue'
                ),
            meta: {
                breadcrumb: [
                    { name: 'Connectors', link: '/connectors' },
                    { name: ':connectorId', link: '/connectors/:connectorId' },
                    { name: 'Links', link: '' },
                ],
                validActivated: 'connectors/activated',
            },
        },
        {
            path: '/connectors/:connectorId/links-create',
            name: 'connectors-links-create',
            props: (route) => ({
                connectorId: parseInt(route.params.connectorId),
            }),
            component: () =>
                import(
                    /* webpackChunkName: "connectors-links" */ '../views/connectors/links/Create.vue'
                ),
            meta: {
                breadcrumb: [
                    { name: 'Connectors', link: '/connectors' },
                    { name: ':connectorId', link: '/connectors/:connectorId' },
                    { name: 'Links', link: '/connectors/:connectorId/links' },
                    { name: 'New Link', link: '' },
                ],
                validActivated: 'connectors/activated',
            },
        },
        {
            path: '/connectors/:connectorId/links/:id',
            name: 'connectors-links-edit',
            props: (route) => ({
                connectorId: parseInt(route.params.connectorId),
                id: parseInt(route.params.id),
            }),
            component: () =>
                import(
                    /* webpackChunkName: "connectors-links" */ '../views/connectors/links/Edit.vue'
                ),
            meta: {
                breadcrumb: [
                    { name: 'Connectors', link: '/connectors' },
                    { name: ':connectorId', link: '/connectors/:connectorId' },
                    { name: 'Links', link: '/connectors/:connectorId/links' },
                    { name: ':id', link: '' },
                ],
                validActivated: 'connectors/activated',
            },
        },
    ];
}

function getRouteLocal() {
    return [
        {
            path: '/sandbox',
            name: 'sandbox-index',
            component: () =>
                import(
                    /* webpackChunkName: "sandbox" */ '../views/sandbox/Index.vue'
                ),
            meta: {
                breadcrumb: [{ name: 'Sandbox', link: '' }],
            },
        },
    ];
}

export default router;
