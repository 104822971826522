
import { library } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import {
    fas,
    /*faAngleDoubleUp,
    faAngleDown,
    faAngleUp,
    faBlog,
    faBuilding,
    faCarSide,
    faCity,
    faCheck,
    faChevronRight,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faCopy,
    faCubes,
    faDrawPolygon,
    faEdit,
    faExchangeAlt,
    faEye,
    faLayerGroup,
    faHome,
    faLocationArrow,
    faMapMarkedAlt,
    faMapMarker,
    faMapPin,
    faMonument,
    faPaperPlane,
    faPencilAlt,
    faPlus,
    faPlusSquare,
    faPowerOff,
    faPuzzlePiece,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faSync,
    faTimes,
    faUserCircle,
    faUserSecret,
    faUsers,
    faRotate,
    faWrench,*/
} from '@fortawesome/free-solid-svg-icons';

library.add(fas);

/*library.add([
    faAngleDoubleUp,
    faAngleDown,
    faAngleUp,
    faBlog,
    faBuilding,
    faCarSide,
    faCity,
    faCheck,
    faChevronRight,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faCopy,
    faCubes,
    faDrawPolygon,
    faEdit,
    faExchangeAlt,
    faEye,
    faLayerGroup,
    faHome,
    faLocationArrow,
    faMapMarkedAlt,
    faMapMarker,
    faMapPin,
    faMonument,
    faPaperPlane,
    faPencilAlt,
    faPlus,
    faPlusSquare,
    faPowerOff,
    faPuzzlePiece,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faSync,
    faTimes,
    faUserCircle,
    faUserSecret,
    faUsers,
    faWrench,
]);*/

window.icons_set = library.definitions.fas;

let Icons = {};

Icons.install = function (Vue, /*options*/) {
    Vue.component('fa', FontAwesomeIcon);
    Vue.component('fa-stacks', FontAwesomeLayers);
};

export default Icons;